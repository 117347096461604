<template>
  <svg
    fill="none"
    height="12"
    viewBox="0 0 14 12"
    width="14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.8 1H2.2C1.53726 1 1 1.55964 1 2.25V9.75C1 10.4404 1.53726 11 2.2 11H11.8C12.4627 11 13 10.4404 13 9.75V2.25C13 1.55964 12.4627 1 11.8 1Z"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
    />
    <path
      d="M13 2.875L7.618 6.4375C7.43276 6.55839 7.21859 6.62251 7 6.62251C6.78141 6.62251 6.56724 6.55839 6.382 6.4375L1 2.875"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
    />
  </svg>
</template>
