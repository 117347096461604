<template>
  <svg
    fill="none"
    height="12"
    viewBox="0 0 12 12"
    width="12"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M11.0007 8.45999V9.95999C11.0013 10.0992 10.9727 10.2371 10.917 10.3647C10.8612 10.4923 10.7793 10.6068 10.6767 10.7009C10.5741 10.7951 10.453 10.8667 10.3211 10.9113C10.1892 10.956 10.0494 10.9725 9.9107 10.96C8.37212 10.7928 6.8942 10.2671 5.5957 9.42499C4.38761 8.65732 3.36337 7.63307 2.5957 6.42499C1.75069 5.12059 1.22482 3.63548 1.0607 2.08999C1.0482 1.95172 1.06464 1.81237 1.10895 1.6808C1.15326 1.54923 1.22448 1.42833 1.31808 1.3258C1.41168 1.22326 1.5256 1.14134 1.65259 1.08525C1.77959 1.02915 1.91687 1.00012 2.0557 0.999987H3.5557C3.79835 0.997599 4.03359 1.08353 4.21758 1.24175C4.40156 1.39998 4.52174 1.61971 4.5557 1.85999C4.61901 2.34002 4.73642 2.81135 4.9057 3.26499C4.97297 3.44395 4.98753 3.63844 4.94765 3.82543C4.90777 4.01241 4.81513 4.18404 4.6807 4.31999L4.0457 4.95499C4.75748 6.20676 5.79393 7.24321 7.0457 7.95499L7.6807 7.31999C7.81664 7.18556 7.98828 7.09291 8.17526 7.05303C8.36224 7.01316 8.55674 7.02772 8.7357 7.09499C9.18934 7.26426 9.66067 7.38168 10.1407 7.44499C10.3836 7.47925 10.6054 7.60159 10.764 7.78873C10.9225 7.97588 11.0068 8.21478 11.0007 8.45999Z"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
      />
      <path
        d="M7.02539 1C8.04452 1.10738 8.99648 1.5594 9.72382 2.28129C10.4512 3.00318 10.9103 3.95171 11.0254 4.97"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
      />
      <path
        d="M7.02539 3C7.51715 3.09697 7.9684 3.33951 8.32058 3.69615C8.67276 4.0528 8.90962 4.50706 9.00039 5"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
      />
    </g>
  </svg>
</template>
